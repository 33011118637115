.table-pagamentos .rdt_TableHeadRow{
  background-color: #27CC4A;
  border-radius:10px 10px 0px 0px;
}
.table-pagamentos .rdt_TableCol_Sortable{
  font-weight: bolder;
  font-size: small;
  color: #fff;
}
.table-pagamentos .rdt_TableBody{
  font-weight: bolder;
}
.table-pagamentos .rdt_TableRow{
  background-color: rgba(0,0,0,.05) !important;
}
.table-pagamentos .jDHQPG{
  display: inline-grid !important;
}
.table-pagamentos{
  padding: 0px 5px;
}

.table-pagamentos .rdt_TableHeader{
  display: none;
}