.filebaseDiv{
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    align-items: center;
}

#filebaseInput {
    display: none;
}
#filebaseLabel {
    width: fit-content;
}
#filebaseLabel:hover{
    background-color: rgb(63, 168, 86);
    transition-duration: 500ms;
}
.filebaseSmall{
    margin-top:5px;
    color: rgb(99, 99, 99);
    font-weight: 100;
}