@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@font-face {
  font-family: CamptonBold;
  src: url("/static/media/Rene Bieder - Campton Bold.9b3f306a.otf");
}

@font-face {
  font-family: CamptonLight;
  src: url("/static/media/Rene Bieder - Campton Light.499130ec.otf");
}

.btn-investir {
  min-height: 24px;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "CamptonLight", "Roboto", Arial, Helvetica, sans-serif;
}

b,
h1,
h2,
h3,
h4,
h5,
strong,
.font-weight-bolder,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "CamptonBold";
  letter-spacing: 0.05rem;
}

.icdHOq,
.gIMaKV {
  background: transparent !important;
}

html,
body,
#root {
  min-height: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
b {
  font-family: "CamptonBold", "Roboto", Arial, Helvetica, sans-serif;
}
input,
button,
.form-control {
  font-family: "Campton", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.error {
  color: rgb(230, 51, 20);
  font-weight: 500;
}

.email-verifided {
  color: rgb(90, 90, 90);
  font-weight: 600;
}

.verify-email {
  display: flex;
  align-items: center;
}
.verify-email input {
  margin-right: 8px;
  max-width: 12rem;
}
.send-code-email {
  text-decoration: underline;
  color: #5c5c5c;
  cursor: pointer;
  border: none;
  background: none;
}

.link {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}
.link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.img-instrucoes {
  width: -webkit-fill-available;
  height: 100%;
}
.aside {
  max-width: 300px;
  border: 1px solid rgb(180, 180, 180);
  padding: 8px;
}
.warn {
  max-width: 300px;
}
.aside img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: red;
}
.qualified {
  color: #666;
}
.qualified-control {
  display: flex;
  flex-flow: row wrap;
  padding: 8px 0px;
}
.qualified-control button {
  font-size: 0.8rem;
  margin: 8px;
  max-width: 120px;
  border-radius: 0 !important;
}

.oav-assign {
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
  margin: 0 auto;
}

#contrato_oav,
#contrato_cvm {
  height: 60vh;
  min-height: 600px;
  background-color: #d8d8d8;
}
#contrato_oav iframe,
#contrato_cvm iframe {
  width: 100%;
  height: 100% !important;
  min-height: 600px;
  position: unset !important;
}

@media (max-height: 820px) {
  #contrato_oav,
  #contrato_cvm {
    height: 40vh;
  }
}

.token-actions {
  margin: 0 auto;
  width: -webkit-max-content;
  width: max-content;
}
.token-actions .form-inline {
  margin-top: 8px;
}

.dashboard {
  display: flex;
  flex-flow: row;
}

.dashboard-nav {
  background-color: #212121;
  border-right: 1px solid #eee;
  color: #fff;
  height: 100vh;
  font-size: 1.5rem;
  width: 30vw;
  max-width: 250px;
  z-index: 800;
}
.dashboard-nav-box {
  margin: 12px 0px;
  position: fixed;
  border-right: 1px solid #eee;
  height: 100vh;
  display: flex;
  flex-flow: column;
  width: 30vw;
  max-width: 250px;
}
.dashboard-nav-links {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  padding: 6px 12px;
  cursor: pointer;
  transition-duration: 200ms;
}
.dashboard-nav .logo {
  width: 240px;
  height: 96;
  margin-bottom: 12px;
}
.logo img {
  width: 100%;
}
.dashboard-nav-links:hover {
  background-color: rgba(255, 255, 255, 0.1);
  padding-left: 20px;
  color: #fff;
  text-decoration: none;
}
.dashboard-nav-box .active {
  background-color: rgba(166, 167, 166, 0.753);
}

.dashboard-header {
  display: flex;
  padding: 8px 12px;
  background-color: #28a745;
  flex-flow: row;
}

.dashboard-header h4 {
  color: #fff;
  justify-self: flex-end;
  align-self: center;
}

.dashboard-header .navbar-light {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 4px 6px;
  border: none;
  margin-right: 12px;
}

.dashboard-header .navbar-light:focus {
  background: rgba(0, 0, 0, 0.3);
}

.termo_box {
  height: 60vh;
  overflow: scroll;
}

.project-resume {
  font-size: 100%;
}

.project-img {
  width: 100%;
  max-height: 400px;
}

@media (max-width: 768px) {
  .project-resume,
  .project-resume .col {
    padding: 8px 1rem !important;
  }
  .aside {
    margin: 0 auto;
  }
  .dashboard {
    flex-flow: column;
  }
  .dashboard-nav {
    position: fixed;
    width: 100%;
  }
  .box-graficos-simulador-captacao {
    margin-top: 12px;
  }
}

.dashboard-nav-mobile {
  display: none;
  position: fixed;
  width: 40vw;
  min-width: 140px;
  max-width: 260px;
  background-color: #212121;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
}

.dashboard-nav-mobile.active {
  display: inline;
  display: initial;
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: black !important;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: black !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: black !important;
}

.dashboard .powered {
  position: absolute;
  bottom: 10px;
  padding: 8px;
}

.powered img {
  max-height: 90px;
}

.close-nav-mobile {
  display: flex;
  color: #fff;
  padding: 0 2em;
  border: none;
  background: none;
  font-size: 2rem;
}

.dashboard-nav-mobile .dashboard-nav-box {
  position: absolute;
  background-color: #212121;
  height: 100vh;
  width: 40vw;
  min-width: 140px;
  max-width: 250px;
}

.dashboard-nav-mobile .logo {
  display: flex;
  justify-content: space-between;
}
.dashboard-nav-mobile .logo img {
  width: 240px;
  height: 96px;
}

@media (max-width: 600px) {
  .dashboard-nav-mobile {
    width: 100vw;
    min-width: unset;
    max-width: unset;
  }
  .dashboard-nav-mobile .dashboard-nav-box {
    width: 100vw;
    min-width: unset;
    max-width: unset;
  }
}
.table table {
  margin: auto;
}
.table td {
  font-size: 1rem;
  vertical-align: middle !important;
}
.btn {
  font-family: "CamptonBold" !important;
}

.table .btn {
  font-size: 0.8rem;
  vertical-align: middle !important;
}
.table th {
  font-size: 1rem;
  font-weight: 600;
  color: rgb(148, 148, 148);
}
.mobile-handle {
  font-size: 1rem !important;
}

.boleto-pendencia,
.anexo-pendencia {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.progress-bar.bg-info {
  background-color: #acb0b1 !important;
}
.progress {
  background-color: #cecece !important;
}

.InvestirDetalhe_simulador_desktop .box-simulador {
  border-color: #00000061 !important;
}
.InvestirDetalhe_simulador_desktop .box-simulador .leftpart h1 {
  font-size: 1rem;
}
.InvestirDetalhe_simulador_desktop .box-simulador .leftpart input {
  background-color: #f7f7f7 !important;
  padding-left: 1vw;
}
.InvestirDetalhe_simulador_desktop .simulador-info ul li:first-of-type {
  font-size: 1.3rem !important;
  font-family: "CamptonBold";
}
.InvestirDetalhe_simulador_desktop .simulador-info ul li {
  list-style: none;
  margin-top: 0.417vw !important;
}
.InvestirDetalhe_simulador_desktop .btn-inco-primary {
  border: none !important;
  padding-left: 0;
}
.InvestirDetalhe_simulador_desktop .btn-inco-primary:hover {
  background-color: transparent !important;
  text-decoration: underline;
  color: rgb(39, 204, 74) !important;
}

.box-simulador {
  float: left;
  width: 100%;
  padding: 15px;
  border: 1px solid #eaeaea;
}
.box-simulador .leftpart h1 {
  font-size: 19px;
}
.box-simulador .leftpart input {
  border: none;
  padding-left: 5px;
  margin-bottom: 1rem;
}
.box-simulador .btn.semfundo {
  background-color: transparent !important;
  box-shadow: none !important;
}
.box-simulador .simulador-info.price ul li {
  margin-bottom: 1vw;
  font-size: 1.302vw !important;
}
.box-simulador .flex-box-simulador-info {
  display: flex;
}
.box-simulador .flex-box-simulador-info .simulador-info {
  width: 45%;
  margin: 12px 0px;
}
.box-simulador .flex-box-simulador-info .simulador-info:last-of-type {
  margin-right: 0 !important;
}
.box-simulador .flex-box-simulador-info .simulador-info b {
  display: flex;
  width: 100%;
  margin-bottom: 2vw;
  text-align: center;
}
.box-simulador .flex-box-simulador-info .simulador-info ul li {
  margin-bottom: 1.4vw;
}

.box-simulador .box-grafico-static {
  display: flex;
  height: 8vw;
}
.box-simulador .container-col {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-right: 10px;
  position: relative;
  flex-direction: column;
}
.box-simulador .container-col .grafico-value {
  display: flex;
  margin-bottom: 5px;
  font-size: 0.938vw;
}
.box-simulador .container-col .grafico-col {
  width: 30px;
}

.compCaptacaoAbas,
.nav-tabs {
  max-width: 100%;
}

.CompSection897 {
  width: 100%;
  display: block;
  padding: 30px auto;
}

.item.tabInvestirDetalheNav {
  margin: 0 8px;
}

.tabInvestirDetalheNav.active .tab-link {
  border-bottom: 3px solid black;
}

.image-gallery-thumbnails-container {
  display: flex !important;
  justify-content: center !important;
}

.box-investidores li {
  list-style: none;
}
.perfil-basic,
.user-picture,
.perfil-nome,
.perfil-recovery-link {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.user-picture figure {
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  width: 106px;
  height: 106px;
  border-radius: 50%;
  background-color: rgb(223, 223, 223);
  border: 1px solid rgb(177, 177, 177);
}
.user-picture .btn {
  font-size: 1rem;
  font-weight: 700;
  opacity: 0.8;
  transition-duration: 300ms;
}
.user-picture .btn:hover {
  opacity: 1;
  text-decoration: underline;
}
.perfil-nome {
  flex-flow: row;
  font-size: 1.2rem;
}
.perfil-recovery-link {
  font-size: 1rem;
}
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.fileContainer .chooseFileButton {
  margin: 0;
  background-color: transparent !important;
  padding: 0;
  font-weight: 600 !important ;
  font-size: 1.2rem;
  color: rgb(11, 40, 102) !important;
}
.fileContainer .chooseFileButton:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  color: #151515;
}

.acompanhe-header {
  display: flex;
  min-height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.acompanhe-header .header-mobile {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(59, 35, 35, 0.3);
  min-width: 220px;
  width: 100%;
  padding: 16px;
  position: relative;
  bottom: -30px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  z-index: 10;
}
.mainMobile .chart {
  border: 1px solid rgb(209, 209, 209);
  padding: 8px;
  max-height: 160px;
}

.header-mobile .mainMobile {
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.acompanhe-header .header-desktop {
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(92, 88, 88, 0.212);
  border-radius: 4px;
  height: 140px;
  position: relative;
  bottom: -20px;
  justify-content: space-evenly;
  align-items: center;
  align-self: flex-end;
  width: 40%;
  min-width: 700px;
  display: flex;
}

.acompanhe-content .content-mobile {
  font-size: 1.2rem;
}

.content-mobile .secondary-background {
  background-color: #e0e0e0;
}

.acompanhe-content .content-desktop span {
  font-size: 1.2rem;
}
.extra-content-desktop span {
  font-weight: 400;
}

button,
input,
.custom-select,
.btn {
  border-radius: 16px !important;
}

.home-cards {
  overflow-x: scroll;
  padding-bottom: 30px;
}

.home-cards img {
  max-width: 400px;
}

.home-cards .btn {
  max-height: 38px !important;
}

input,
.form-control {
  background-color: rgba(226, 216, 200, 0) !important;
  border-color: rgba(0, 0, 0, 0.233) !important;
}

.header-powered-bar {
  background-color: #27cc4a;
  color: #fff;
  font-weight: 600;
  font-size: 90%;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 5vw;
}
.header-powered-bar .container {
  display: flex;
  justify-content: flex-end;
}

.button-hack {
  flex: 1 1;
}

.home-page {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.card-login {
  display: flex;
  align-items: center;
  flex-flow: column;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.199);
  border-radius: 8px;
  max-width: 400px !important;
  margin: auto;
}

.card-login-title {
  font-family: CamptonBold;
  font-size: 28px;
  max-width: 80%;
}

.card-login-content {
  max-width: 80%;
  display: flex;
  flex-flow: column;
  padding: 16px 0px;
}

.card-login-content form {
  display: flex;
  flex-flow: column;
  padding: 16px 0px;
}

.card-login-footer {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.qualified-box-info {
  padding: 8px;
  background: #e0e0e0;
  margin: 0px 0px 8px 0px;
}

.filebaseDiv{
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    align-items: center;
}

#filebaseInput {
    display: none;
}
#filebaseLabel2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px !important;
}
.filebaseSmall{
    margin-top:5px;
    color: rgb(99, 99, 99);
    font-weight: 100;
}
.compCaptacaoAbas .nav.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.compCaptacaoAbas .nav.nav-tabs::-webkit-scrollbar {
  display: none !important;
  height: 0 !important;
}
.compCaptacaoAbas .nav.nav-tabs::-moz-scrollbar {
  display: none !important;
  height: 0 !important;
}

.compCaptacaoAbas .nav.nav-tabs {
  padding: 1.458vw 0 !important;
  border-color: #00000061 !important;
}
.compCaptacaoAbas .nav-tabs li a {
  white-space: nowrap;
  font-size: 1rem;
}
.compCaptacaoAbas .nav.nav-tabs li a {
  padding: 1vw 0.8vw;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex;
}
.image-gallery-thumbnail {
  padding: 0 !important;
  overflow: hidden;
  max-height: 80px;
  margin-top: 3vw;
  margin-bottom: 3vw;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.image-gallery-thumbnail.active {
  border: 2px solid #27cc4a !important;
  border-radius: 3px;
}
.image-gallery-thumbnail-inner {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.image-gallery-thumbnail-inner img {
  width: 100% !important;
  float: left;
}
.image-gallery-image {
  position: relative !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-wrapper {
  width: 100%;
  height: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-gallery-image .play-button {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
  -webkit-border-radius: 50%;
  align-items: center;
  text-align: center;
  font-size: 30px;
  background-color: rgba(39, 204, 74, 0.7);
  color: #fff;
}
.image-gallery-image .play-button:hover {
  background-color: rgba(39, 204, 74, 1);
}

.containerTabContent.vendas .right_vendas .legenda .legenda-title,
.containerTabContent.vendas .right_vendas .legenda .option {
}

@media (max-width: 1024px) {
  .video-wrapper {
    height: 50vw;
  }
}

.containerTabContent.juridico .cards {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start !important;
}
.containerTabContent.juridico .cards .card {
  height: auto !important;
  padding: 2vw !important;
  display: flex;
  flex: calc(100% / 3 - 105px) 1 !important;
}
.containerTabContent .card-content p {
  font-size: 1rem !important;
  line-height: 1.5vw !important;
}

.containerTabContent .comment {
  margin-bottom: 4vw;
}
.containerTabContent .author {
  display: flex;
  flex-direction: row;
}
.containerTabContent .author .author-image {
  width: 4vw;
  height: 4vw;
  margin-right: 0.8rem;
  background-size: cover;
}
.containerTabContent .author .author-content {
  margin: 0.8vw;
}
.containerTabContent .author .author-name {
  font-weight: bold;
  font-size: 1rem;
}
.containerTabContent .author .date-comment {
  font-size: 0.8rem;
  margin-top: 0.5vw;
}
.containerTabContent .wrapper-comment .content p {
  font-size: 1rem;
  padding-left: 5.5vw;
}
.containerTabContent .comment .responder {
  padding-left: 5.5vw;
}
.containerTabContent .comment .responder a {
  font-size: 1rem;
  font-weight: bold;
  color: #27cc4a;
}
.containerTabContent .btn-large .btn_responder_forum {
  border: 0.104vw solid #27cc4a !important;
  background-color: #27cc4a !important;
  color: #fff !important;
  padding: 1.042vw 1.458vw;
  font-size: 1rem;
  line-height: 1.042vw;
  -webkit-border-radius: 1.615vw;
  font-weight: bold;
}
.containerTabContent .btn_responder_forum:hover {
  border: 0.104vw solid #fff !important;
  background-color: #fff !important;
  color: #27cc4a !important;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.textoTab b,
strong {
}

.table-pagamentos .rdt_TableHeadRow{
  background-color: #27CC4A;
  border-radius:10px 10px 0px 0px;
}
.table-pagamentos .rdt_TableCol_Sortable{
  font-weight: bolder;
  font-size: small;
  color: #fff;
}
.table-pagamentos .rdt_TableBody{
  font-weight: bolder;
}
.table-pagamentos .rdt_TableRow{
  background-color: rgba(0,0,0,.05) !important;
}
.table-pagamentos .jDHQPG{
  display: inline-grid !important;
}
.table-pagamentos{
  padding: 0px 5px;
}

.table-pagamentos .rdt_TableHeader{
  display: none;
}
.compCaptacaoAbas {
  width: 100%;
}
.compCaptacaoAbas .nav-tabs {
  display: flex;
  width: 100%;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  flex-direction: row;
  justify-content: space-between;
}
.compCaptacaoAbas .nav-tabs li a {
  display: flex;
  position: relative;
  padding: 10px;
  font-family: "CamptonLight";
  z-index: 3;
  color: #151515;
  text-decoration: none;
}
/* .compCaptacaoAbas .nav-tabs li span:hover {
  content: "";
  border: 3px solid #27cc4a;
  bottom: 10px;
  position: absolute;
  z-index: -8;
  width: 100%;
}
.compCaptacaoAbas .nav-tabs li.active a::after {
  content: "";
  border: 3px solid #27cc4a;
  bottom: 10px;
  position: absolute;
  z-index: -8;
  width: 100%;
} */
.compCaptacaoAbas .tab-content {
  display: flex !important;
  margin-top: 20px;
  width: 100%;
}

.compCaptacaoAbas .tab-content,
.tab-content .tab-pane {
  width: 100%;
  float: left;
}
.compCaptacaoAbas .tab-content img {
  max-width: 100%;
}
.compCaptacaoAbas .Dropdown {
  display: none !important;
}

.containerTabContent.empresa {
  margin-top: 20px;
}
.containerTabContent.empresa section,
.containerTabContent.empresa article {
  padding: 0;
}
.containerTabContent.empresa .texto_empresa h1 {
  font-size: 20px;
  font-family: "CamptonBold";
  margin-bottom: 20px;
}
.containerTabContent.empresa .texto_empresa {
  font-family: "CamptonLight";
}
.containerTabContent.empresa .logo_empresa {
}
.containerTabContent.empresa .logo_empresa img {
  border: 1px solid #e5e5e5;
  max-width: 100%;
  padding: 20px;
  border-radius: 5px;
}
.containerTabContent .textoTab {
  font-family: "CamptonLight";
}
.containerTabContent.empreendimento .galeriaTabEmpreendimento {
  display: block;
  max-width: 100%;
  overflow: hidden;
}
.galeriaTabEmpreendimento .owl-nav {
  width: 100%;
  position: relative;
  margin: 10px auto 0px;
  position: relative;
  z-index: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}
.galeriaTabEmpreendimento .arrow {
  background-color: #fff;
  top: -80px;
  border: none;
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0 11px 22px 0 rgba(35, 35, 35, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: initial;
}
.galeriaTabEmpreendimento .arrow.arrow-previous {
  left: 0;
}
.galeriaTabEmpreendimento .arrow.arrow-next {
  right: 0;
}
.containerTabContent.empreendimento .galeriaTabEmpreendimento .owl-item {
  display: inline-block;
}
.containerTabContent.empreendimento .carrousel_empreendimento .tab-slide {
  display: none !important;
}
.containerTabContent.empreendimento
  .carrousel_empreendimento
  .tab-slide.active {
  display: block !important;
}
.containerTabContent.empreendedor .galeriaTabEmpreendedor {
  display: block;
  max-width: 100%;
  overflow: hidden;
}
.galeriaTabEmpreendedor .owl-nav {
  width: 100%;
  position: relative;
  margin: 10px auto 0px;
  position: relative;
  z-index: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}
.galeriaTabEmpreendedor .arrow {
  background-color: #fff;
  top: -180px;
  border: none;
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0 11px 22px 0 rgba(35, 35, 35, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: initial;
}
.galeriaTabEmpreendedor .arrow.arrow-previous {
  left: 0;
}
.galeriaTabEmpreendedor .arrow.arrow-next {
  right: 0;
}
.containerTabContent.empreendedor .galeriaTabEmpreendedor .owl-item {
  display: inline-block;
}
.containerTabContent.empreendedor .galeriaTabEmpreendedor .owl-item img {
  max-width: 100%;
}

.containerTabContent.empreendedor .image-gallery-image {
  max-width: 360px;
  min-width: 220px;
  margin: 0 auto;
}

.containerTabContent.investidores .box-investidores li:not(:last-child) {
  margin-right: 16px;
}
.containerTabContent.investidores .box-investidores li {
  margin: 0 0 16px;
}
.containerTabContent.viabilidade .cards {
  width: 100%;
  margin: 55px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.containerTabContent.viabilidade .cards .card {
  width: 335px;
  flex-shrink: 0;
  margin: 0 0 35px;
  padding: 20px 0 25px;
  margin-right: 35px;
  text-align: center;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  box-shadow: 10px 17px 40px rgba(121, 102, 255, 0.1);
}
.containerTabContent.viabilidade .description {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.containerTabContent.viabilidade .card-title {
  color: #29cc4a;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-family: "CamptonBold";
}
.containerTabContent.viabilidade .card-content {
  width: 100%;
  margin: 30px 0 0;
  padding: 0 40px;
}
.containerTabContent.viabilidade .card-content p {
  color: #3a3a3a;
  font-size: 16px;
  font-weight: 400;
  line-height: 0.1 0.2;
  margin: 0 0 25px;
  padding: 0;
}

.containerTabContent.juridico .card-row > div {
  display: flex;
  min-height: 190px;
}

.card-row .btn-success {
  border: none;
}

.card {
  width: 100%;
}

.containerTabContent.juridico .card-body {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.containerTabContent.vendas .left_vendas .title {
  color: #3a3a3a;
  font-size: 18px;
  width: 100%;
  display: block;
  font-weight: 700;
  margin: 0 0 10px;
  padding: 0;
}
.containerTabContent.vendas .left_vendas .unidades {
  color: #29cc4a;
  display: block;
  width: 100%;
  font-family: "CamptonBold";
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.containerTabContent.vendas .right_vendas .title {
  color: #3a3a3a;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}
.containerTabContent.vendas .right_vendas .title span {
  color: #29cc4a;
  font-size: 26px;
  margin-left: 6px;
}
.containerTabContent.vendas .right_vendas .list {
  width: 295px;
  margin: 40px 0 0;
  padding: 0;
}
.containerTabContent.vendas .right_vendas .list ul {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.containerTabContent.vendas .right_vendas .list ul li {
  position: relative;
  margin: 0 0 15px;
  padding: 0;
}
.containerTabContent.vendas .right_vendas .list ul li:not(:last-child) {
  margin-right: 30px;
}
.containerTabContent.vendas .right_vendas .list ul li:nth-child(5n + 0) {
  margin-right: 0;
}

.containerTabContent.vendas .right_vendas .legenda {
  position: absolute;
  right: 0;
  bottom: 15px;
  padding: 10px 12px 10px 10px;
  border: 1px solid #022169;
}
.containerTabContent.vendas .right_vendas .legenda .legenda-title {
  color: #3a3a3a;
  font-family: "Circular Std";
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.containerTabContent.vendas .right_vendas .legenda .option {
  color: #3a3a3a;
  font-family: "Circular Std";
  font-size: 13px;
  font-weight: 700;
  position: relative;
  margin: 5px 0 4px;
  padding: 0 0 0 18px;
}
.containerTabContent.vendas .right_vendas .legenda .option:last-child {
  margin: 0;
}
.containerTabContent.vendas .right_vendas .legenda .option::before {
  content: "";
  background-color: #29cc4a;
  position: absolute;
  top: 1px;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.containerTabContent.vendas .right_vendas .legenda .option:last-child::before {
  background-color: #3a3a3a;
}

.containerTabContent.avancoObra .row {
  width: 100%;
}
.containerTabContent.avancoObra .box_graficos {
  width: 100%;
  margin: 0;
  margin-top: 30px;
  padding: 0 0 25px;
}
.containerTabContent.avancoObra .progress {
  width: 100%;
  margin: 0;
  height: auto;
  overflow: inherit;
  padding: 10px;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
}

.containerTabContent.avancoObra .progress:not(:last-child) {
  margin-bottom: 15px;
}
.containerTabContent.avancoObra .progress .progresss-title {
  width: 100%;
  display: flex;
  font-size: 17px;
  font-family: "CamptonBold";
}
.containerTabContent.avancoObra .progress .flex {
  width: 100% !important;
  margin: 12px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.containerTabContent.avancoObra .progress .flex .bar {
  opacity: 1;
  transition-delay: 1.2s;
  will-change: opacity;
  transition: opacity 0.6s;
  background-color: #e7e7e7;
  width: 100%;
  height: 13px;
  margin: 0 0 10px;
  padding: 0;
  border-radius: 15px;
  overflow: hidden;
}
.containerTabContent.avancoObra .progress .flex .bar .line {
  background-color: #29cc4a;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 15px;
}
.containerTabContent.avancoObra .progress .flex .percentage {
  color: #3a3a3a;
  font-size: 16px;
  font-weight: 400;
  margin: -11px 0 0;
  padding: 0 0 0 15px;
  white-space: nowrap;
  width: 125px;
  flex-shrink: 0;
}

.containerTabContent.operacao .galeriaTabOperacao {
  display: block;
  max-width: 100%;
  overflow: hidden;
}
.galeriaTabOperacao .owl-nav {
  width: 100%;
  position: relative;
  margin: 10px auto 0px;
  position: relative;
  z-index: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}
.galeriaTabOperacao .arrow {
  background-color: #fff;
  top: -180px;
  border: none;
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0 11px 22px 0 rgba(35, 35, 35, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: initial;
}
.galeriaTabOperacao .arrow.arrow-previous {
  left: 0;
}
.galeriaTabOperacao .arrow.arrow-next {
  right: 0;
}
.containerTabContent.operacao .galeriaTabOperacao .owl-item {
  display: inline-block;
}

/* FIX CARDS */

.cards {
  display: grid !important;
  grid-auto-rows: auto;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.cards .card {
  width: unset !important;
  display: flex !important;
  flex-flow: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: unset !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) !important;
}

.tab-link {
  cursor: pointer;
  white-space: nowrap;
}
.tab-link:hover {
  border-bottom: 3px solid black;
}

.container.verificar {
  padding-top: 16px;
  padding-bottom: 16px;
}

.verificar .header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.verificar .counter-step {
  font-size: 1.6rem;
}

.verificar .step-description {
  font-size: 1.5rem;
}

.verificar.content {
  margin: 12px 0px;
}

.verificar.content .submit-row {
  justify-content: flex-end !important;
}

@media (min-width: 769px) {
  .container.verificar {
    margin: 80px 80px 0px 80px !important;
  }
}

.filebaseDiv{
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    align-items: center;
}

#filebaseInput {
    display: none;
}
#filebaseLabel {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
#filebaseLabel:hover{
    background-color: rgb(63, 168, 86);
    transition-duration: 500ms;
}
.filebaseSmall{
    margin-top:5px;
    color: rgb(99, 99, 99);
    font-weight: 100;
}
.whatsapp__button {
  position: absolute;
  width: 48px;
  height: 48px;
  bottom: 15px;
  right: 15px;
  z-index: 20;
}
.whatsapp__button img {
}

.bar {
  display: flex;
  flex-flow: row nowrap;
  max-height: 80px;
  align-items: flex-end;
  margin-bottom: 20px;
}

.total-bar {
  background: #eee;
  flex: 1 1;
  height: 12px;
}

.progress-bar {
  background: #27cc4a !important;
  height: 12px;
}

.bars {
  flex: 1 1;
  display: flex;
  flex-flow: row nowrap;
}

.bars > .progress-bar {
  flex: 1 1;
}

.divider-bar {
  width: 3px;
}

.ballon {
  display: flex;
  font-size: 80%;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgb(214, 214, 214);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  padding: 2px 6px;
  min-width: 110px;
  min-height: 30px;
  border-radius: 4px;
  position: absolute;
  top: 45px;
  right: calc(50% - 55px);
}
.ballon::before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.212);
  bottom: -8px;
  content: "";
  position: absolute;
}

.ballon::after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid rgb(255, 255, 255);

  bottom: -7px;
  content: "";
  position: absolute;
}

.total-bar .progress-bar {
  border-radius: 0px 8px 8px 0px;
}

.values h4 {
  margin: 0px;
  font-size: 32px;
}

.values span {
  font-size: 1rem;
}

.container-ccb .header .col .empreendimento {
    font-size: 1.5rem;
}
.container-ccb .header .col > spam {
    font-size: .8rem;
    color: #868686;
    font-family: 'CamptonLight';
}
.container-ccb .body {
    padding:25px 0px;
    min-height: 200px;
    max-height: 400px;
    overflow-y: scroll;
    margin-bottom: 15px;
    /*background: rgb(209, 209, 209);*/
}
.assinaturas-table {
    font-family: 'CamptonLight';
    font-size: .8rem;
}
.container-token{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.checkbox-token{
    display: flex;
    flex-direction: row;
    font-family: 'CamptonLight';
    font-size: .8rem;
}
.token{
    width: 12rem !important;
}
.token .form-control {
    padding: 15px !important;
}
.token .btn {
    padding: 10px !important;
    height:38px !important;
}
.sendToken{
    display: flex;
    flex-flow: column;
    align-items: center;
}
