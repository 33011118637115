.container.verificar {
  padding-top: 16px;
  padding-bottom: 16px;
}

.verificar .header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.verificar .counter-step {
  font-size: 1.6rem;
}

.verificar .step-description {
  font-size: 1.5rem;
}

.verificar.content {
  margin: 12px 0px;
}

.verificar.content .submit-row {
  justify-content: flex-end !important;
}

@media (min-width: 769px) {
  .container.verificar {
    margin: 80px 80px 0px 80px !important;
  }
}
