.compCaptacaoAbas .nav.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.compCaptacaoAbas .nav.nav-tabs::-webkit-scrollbar {
  display: none !important;
  height: 0 !important;
}
.compCaptacaoAbas .nav.nav-tabs::-moz-scrollbar {
  display: none !important;
  height: 0 !important;
}

.compCaptacaoAbas .nav.nav-tabs {
  padding: 1.458vw 0 !important;
  border-color: #00000061 !important;
}
.compCaptacaoAbas .nav-tabs li a {
  white-space: nowrap;
  font-size: 1rem;
}
.compCaptacaoAbas .nav.nav-tabs li a {
  padding: 1vw 0.8vw;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex;
}
.image-gallery-thumbnail {
  padding: 0 !important;
  overflow: hidden;
  max-height: 80px;
  margin-top: 3vw;
  margin-bottom: 3vw;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.image-gallery-thumbnail.active {
  border: 2px solid #27cc4a !important;
  border-radius: 3px;
}
.image-gallery-thumbnail-inner {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.image-gallery-thumbnail-inner img {
  width: 100% !important;
  float: left;
}
.image-gallery-image {
  position: relative !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-wrapper {
  width: 100%;
  height: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-gallery-image .play-button {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
  -webkit-border-radius: 50%;
  align-items: center;
  text-align: center;
  font-size: 30px;
  background-color: rgba(39, 204, 74, 0.7);
  color: #fff;
}
.image-gallery-image .play-button:hover {
  background-color: rgba(39, 204, 74, 1);
}

.containerTabContent.vendas .right_vendas .legenda .legenda-title,
.containerTabContent.vendas .right_vendas .legenda .option {
}

@media (max-width: 1024px) {
  .video-wrapper {
    height: 50vw;
  }
}

.containerTabContent.juridico .cards {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start !important;
}
.containerTabContent.juridico .cards .card {
  height: auto !important;
  padding: 2vw !important;
  display: flex;
  flex: calc(100% / 3 - 105px) !important;
}
.containerTabContent .card-content p {
  font-size: 1rem !important;
  line-height: 1.5vw !important;
}

.containerTabContent .comment {
  margin-bottom: 4vw;
}
.containerTabContent .author {
  display: flex;
  flex-direction: row;
}
.containerTabContent .author .author-image {
  width: 4vw;
  height: 4vw;
  margin-right: 0.8rem;
  background-size: cover;
}
.containerTabContent .author .author-content {
  margin: 0.8vw;
}
.containerTabContent .author .author-name {
  font-weight: bold;
  font-size: 1rem;
}
.containerTabContent .author .date-comment {
  font-size: 0.8rem;
  margin-top: 0.5vw;
}
.containerTabContent .wrapper-comment .content p {
  font-size: 1rem;
  padding-left: 5.5vw;
}
.containerTabContent .comment .responder {
  padding-left: 5.5vw;
}
.containerTabContent .comment .responder a {
  font-size: 1rem;
  font-weight: bold;
  color: #27cc4a;
}
.containerTabContent .btn-large .btn_responder_forum {
  border: 0.104vw solid #27cc4a !important;
  background-color: #27cc4a !important;
  color: #fff !important;
  padding: 1.042vw 1.458vw;
  font-size: 1rem;
  line-height: 1.042vw;
  -webkit-border-radius: 1.615vw;
  font-weight: bold;
}
.containerTabContent .btn_responder_forum:hover {
  border: 0.104vw solid #fff !important;
  background-color: #fff !important;
  color: #27cc4a !important;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.textoTab b,
strong {
}
