.container-ccb .header .col .empreendimento {
    font-size: 1.5rem;
}
.container-ccb .header .col > spam {
    font-size: .8rem;
    color: #868686;
    font-family: 'CamptonLight';
}
.container-ccb .body {
    padding:25px 0px;
    min-height: 200px;
    max-height: 400px;
    overflow-y: scroll;
    margin-bottom: 15px;
    /*background: rgb(209, 209, 209);*/
}
.assinaturas-table {
    font-family: 'CamptonLight';
    font-size: .8rem;
}
.container-token{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.checkbox-token{
    display: flex;
    flex-direction: row;
    font-family: 'CamptonLight';
    font-size: .8rem;
}
.token{
    width: 12rem !important;
}
.token .form-control {
    padding: 15px !important;
}
.token .btn {
    padding: 10px !important;
    height:38px !important;
}
.sendToken{
    display: flex;
    flex-flow: column;
    align-items: center;
}