@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

@import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: CamptonBold;
  src: url("./assets/font/Rene\ Bieder\ -\ Campton\ Bold.otf");
}

@font-face {
  font-family: CamptonLight;
  src: url("./assets/font/Rene\ Bieder\ -\ Campton\ Light.otf");
}

.btn-investir {
  min-height: 24px;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "CamptonLight", "Roboto", Arial, Helvetica, sans-serif;
}

b,
h1,
h2,
h3,
h4,
h5,
strong,
.font-weight-bolder,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "CamptonBold";
  letter-spacing: 0.05rem;
}

.icdHOq,
.gIMaKV {
  background: transparent !important;
}

html,
body,
#root {
  min-height: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
b {
  font-family: "CamptonBold", "Roboto", Arial, Helvetica, sans-serif;
}
input,
button,
.form-control {
  font-family: "Campton", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.error {
  color: rgb(230, 51, 20);
  font-weight: 500;
}

.email-verifided {
  color: rgb(90, 90, 90);
  font-weight: 600;
}

.verify-email {
  display: flex;
  align-items: center;
}
.verify-email input {
  margin-right: 8px;
  max-width: 12rem;
}
.send-code-email {
  text-decoration: underline;
  color: #5c5c5c;
  cursor: pointer;
  border: none;
  background: none;
}

.link {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}
.link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.img-instrucoes {
  width: -webkit-fill-available;
  height: 100%;
}
.aside {
  max-width: 300px;
  border: 1px solid rgb(180, 180, 180);
  padding: 8px;
}
.warn {
  max-width: 300px;
}
.aside img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: red;
}
.qualified {
  color: #666;
}
.qualified-control {
  display: flex;
  flex-flow: row wrap;
  padding: 8px 0px;
}
.qualified-control button {
  font-size: 0.8rem;
  margin: 8px;
  max-width: 120px;
  border-radius: 0 !important;
}

.oav-assign {
  width: max-content;
  max-width: 100%;
  margin: 0 auto;
}

#contrato_oav,
#contrato_cvm {
  height: 60vh;
  min-height: 600px;
  background-color: #d8d8d8;
}
#contrato_oav iframe,
#contrato_cvm iframe {
  width: 100%;
  height: 100% !important;
  min-height: 600px;
  position: unset !important;
}

@media (max-height: 820px) {
  #contrato_oav,
  #contrato_cvm {
    height: 40vh;
  }
}

.token-actions {
  margin: 0 auto;
  width: max-content;
}
.token-actions .form-inline {
  margin-top: 8px;
}

.dashboard {
  display: flex;
  flex-flow: row;
}

.dashboard-nav {
  background-color: #212121;
  border-right: 1px solid #eee;
  color: #fff;
  height: 100vh;
  font-size: 1.5rem;
  width: 30vw;
  max-width: 250px;
  z-index: 800;
}
.dashboard-nav-box {
  margin: 12px 0px;
  position: fixed;
  border-right: 1px solid #eee;
  height: 100vh;
  display: flex;
  flex-flow: column;
  width: 30vw;
  max-width: 250px;
}
.dashboard-nav-links {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  padding: 6px 12px;
  cursor: pointer;
  transition-duration: 200ms;
}
.dashboard-nav .logo {
  width: 240px;
  height: 96;
  margin-bottom: 12px;
}
.logo img {
  width: 100%;
}
.dashboard-nav-links:hover {
  background-color: rgba(255, 255, 255, 0.1);
  padding-left: 20px;
  color: #fff;
  text-decoration: none;
}
.dashboard-nav-box .active {
  background-color: rgba(166, 167, 166, 0.753);
}

.dashboard-header {
  display: flex;
  padding: 8px 12px;
  background-color: #28a745;
  flex-flow: row;
}

.dashboard-header h4 {
  color: #fff;
  justify-self: flex-end;
  align-self: center;
}

.dashboard-header .navbar-light {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 4px 6px;
  border: none;
  margin-right: 12px;
}

.dashboard-header .navbar-light:focus {
  background: rgba(0, 0, 0, 0.3);
}

.termo_box {
  height: 60vh;
  overflow: scroll;
}

.project-resume {
  font-size: 100%;
}

.project-img {
  width: 100%;
  max-height: 400px;
}

@media (max-width: 768px) {
  .project-resume,
  .project-resume .col {
    padding: 8px 1rem !important;
  }
  .aside {
    margin: 0 auto;
  }
  .dashboard {
    flex-flow: column;
  }
  .dashboard-nav {
    position: fixed;
    width: 100%;
  }
  .box-graficos-simulador-captacao {
    margin-top: 12px;
  }
}

.dashboard-nav-mobile {
  display: none;
  position: fixed;
  width: 40vw;
  min-width: 140px;
  max-width: 260px;
  background-color: #212121;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
}

.dashboard-nav-mobile.active {
  display: initial;
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: black !important;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: black !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: black !important;
}

.dashboard .powered {
  position: absolute;
  bottom: 10px;
  padding: 8px;
}

.powered img {
  max-height: 90px;
}

.close-nav-mobile {
  display: flex;
  color: #fff;
  padding: 0 2em;
  border: none;
  background: none;
  font-size: 2rem;
}

.dashboard-nav-mobile .dashboard-nav-box {
  position: absolute;
  background-color: #212121;
  height: 100vh;
  width: 40vw;
  min-width: 140px;
  max-width: 250px;
}

.dashboard-nav-mobile .logo {
  display: flex;
  justify-content: space-between;
}
.dashboard-nav-mobile .logo img {
  width: 240px;
  height: 96px;
}

@media (max-width: 600px) {
  .dashboard-nav-mobile {
    width: 100vw;
    min-width: unset;
    max-width: unset;
  }
  .dashboard-nav-mobile .dashboard-nav-box {
    width: 100vw;
    min-width: unset;
    max-width: unset;
  }
}
.table table {
  margin: auto;
}
.table td {
  font-size: 1rem;
  vertical-align: middle !important;
}
.btn {
  font-family: "CamptonBold" !important;
}

.table .btn {
  font-size: 0.8rem;
  vertical-align: middle !important;
}
.table th {
  font-size: 1rem;
  font-weight: 600;
  color: rgb(148, 148, 148);
}
.mobile-handle {
  font-size: 1rem !important;
}

.boleto-pendencia,
.anexo-pendencia {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.progress-bar.bg-info {
  background-color: #acb0b1 !important;
}
.progress {
  background-color: #cecece !important;
}

.InvestirDetalhe_simulador_desktop .box-simulador {
  border-color: #00000061 !important;
}
.InvestirDetalhe_simulador_desktop .box-simulador .leftpart h1 {
  font-size: 1rem;
}
.InvestirDetalhe_simulador_desktop .box-simulador .leftpart input {
  background-color: #f7f7f7 !important;
  padding-left: 1vw;
}
.InvestirDetalhe_simulador_desktop .simulador-info ul li:first-of-type {
  font-size: 1.3rem !important;
  font-family: "CamptonBold";
}
.InvestirDetalhe_simulador_desktop .simulador-info ul li {
  list-style: none;
  margin-top: 0.417vw !important;
}
.InvestirDetalhe_simulador_desktop .btn-inco-primary {
  border: none !important;
  padding-left: 0;
}
.InvestirDetalhe_simulador_desktop .btn-inco-primary:hover {
  background-color: transparent !important;
  text-decoration: underline;
  color: rgb(39, 204, 74) !important;
}

.box-simulador {
  float: left;
  width: 100%;
  padding: 15px;
  border: 1px solid #eaeaea;
}
.box-simulador .leftpart h1 {
  font-size: 19px;
}
.box-simulador .leftpart input {
  border: none;
  padding-left: 5px;
  margin-bottom: 1rem;
}
.box-simulador .btn.semfundo {
  background-color: transparent !important;
  box-shadow: none !important;
}
.box-simulador .simulador-info.price ul li {
  margin-bottom: 1vw;
  font-size: 1.302vw !important;
}
.box-simulador .flex-box-simulador-info {
  display: flex;
}
.box-simulador .flex-box-simulador-info .simulador-info {
  width: 45%;
  margin: 12px 0px;
}
.box-simulador .flex-box-simulador-info .simulador-info:last-of-type {
  margin-right: 0 !important;
}
.box-simulador .flex-box-simulador-info .simulador-info b {
  display: flex;
  width: 100%;
  margin-bottom: 2vw;
  text-align: center;
}
.box-simulador .flex-box-simulador-info .simulador-info ul li {
  margin-bottom: 1.4vw;
}

.box-simulador .box-grafico-static {
  display: flex;
  height: 8vw;
}
.box-simulador .container-col {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-right: 10px;
  position: relative;
  flex-direction: column;
}
.box-simulador .container-col .grafico-value {
  display: flex;
  margin-bottom: 5px;
  font-size: 0.938vw;
}
.box-simulador .container-col .grafico-col {
  width: 30px;
}

.compCaptacaoAbas,
.nav-tabs {
  max-width: 100%;
}

.CompSection897 {
  width: 100%;
  display: block;
  padding: 30px auto;
}

.item.tabInvestirDetalheNav {
  margin: 0 8px;
}

.tabInvestirDetalheNav.active .tab-link {
  border-bottom: 3px solid black;
}

.image-gallery-thumbnails-container {
  display: flex !important;
  justify-content: center !important;
}

.box-investidores li {
  list-style: none;
}
.perfil-basic,
.user-picture,
.perfil-nome,
.perfil-recovery-link {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.user-picture figure {
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  width: 106px;
  height: 106px;
  border-radius: 50%;
  background-color: rgb(223, 223, 223);
  border: 1px solid rgb(177, 177, 177);
}
.user-picture .btn {
  font-size: 1rem;
  font-weight: 700;
  opacity: 0.8;
  transition-duration: 300ms;
}
.user-picture .btn:hover {
  opacity: 1;
  text-decoration: underline;
}
.perfil-nome {
  flex-flow: row;
  font-size: 1.2rem;
}
.perfil-recovery-link {
  font-size: 1rem;
}
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.fileContainer .chooseFileButton {
  margin: 0;
  background-color: transparent !important;
  padding: 0;
  font-weight: 600 !important ;
  font-size: 1.2rem;
  color: rgb(11, 40, 102) !important;
}
.fileContainer .chooseFileButton:hover {
  transform: translateY(-3px);
  color: #151515;
}

.acompanhe-header {
  display: flex;
  min-height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.acompanhe-header .header-mobile {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(59, 35, 35, 0.3);
  min-width: 220px;
  width: 100%;
  padding: 16px;
  position: relative;
  bottom: -30px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  z-index: 10;
}
.mainMobile .chart {
  border: 1px solid rgb(209, 209, 209);
  padding: 8px;
  max-height: 160px;
}

.header-mobile .mainMobile {
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.acompanhe-header .header-desktop {
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(92, 88, 88, 0.212);
  border-radius: 4px;
  height: 140px;
  position: relative;
  bottom: -20px;
  justify-content: space-evenly;
  align-items: center;
  align-self: flex-end;
  width: 40%;
  min-width: 700px;
  display: flex;
}

.acompanhe-content .content-mobile {
  font-size: 1.2rem;
}

.content-mobile .secondary-background {
  background-color: #e0e0e0;
}

.acompanhe-content .content-desktop span {
  font-size: 1.2rem;
}
.extra-content-desktop span {
  font-weight: 400;
}

button,
input,
.custom-select,
.btn {
  border-radius: 16px !important;
}

.home-cards {
  overflow-x: scroll;
  padding-bottom: 30px;
}

.home-cards img {
  max-width: 400px;
}

.home-cards .btn {
  max-height: 38px !important;
}

input,
.form-control {
  background-color: rgba(226, 216, 200, 0) !important;
  border-color: rgba(0, 0, 0, 0.233) !important;
}

.header-powered-bar {
  background-color: #27cc4a;
  color: #fff;
  font-weight: 600;
  font-size: 90%;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 5vw;
}
.header-powered-bar .container {
  display: flex;
  justify-content: flex-end;
}

.button-hack {
  flex: 1;
}

.home-page {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.card-login {
  display: flex;
  align-items: center;
  flex-flow: column;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.199);
  border-radius: 8px;
  max-width: 400px !important;
  margin: auto;
}

.card-login-title {
  font-family: CamptonBold;
  font-size: 28px;
  max-width: 80%;
}

.card-login-content {
  max-width: 80%;
  display: flex;
  flex-flow: column;
  padding: 16px 0px;
}

.card-login-content form {
  display: flex;
  flex-flow: column;
  padding: 16px 0px;
}

.card-login-footer {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.qualified-box-info {
  padding: 8px;
  background: #e0e0e0;
  margin: 0px 0px 8px 0px;
}
