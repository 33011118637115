.compCaptacaoAbas {
  width: 100%;
}
.compCaptacaoAbas .nav-tabs {
  display: flex;
  width: 100%;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  flex-direction: row;
  justify-content: space-between;
}
.compCaptacaoAbas .nav-tabs li a {
  display: flex;
  position: relative;
  padding: 10px;
  font-family: "CamptonLight";
  z-index: 3;
  color: #151515;
  text-decoration: none;
}
/* .compCaptacaoAbas .nav-tabs li span:hover {
  content: "";
  border: 3px solid #27cc4a;
  bottom: 10px;
  position: absolute;
  z-index: -8;
  width: 100%;
}
.compCaptacaoAbas .nav-tabs li.active a::after {
  content: "";
  border: 3px solid #27cc4a;
  bottom: 10px;
  position: absolute;
  z-index: -8;
  width: 100%;
} */
.compCaptacaoAbas .tab-content {
  display: flex !important;
  margin-top: 20px;
  width: 100%;
}

.compCaptacaoAbas .tab-content,
.tab-content .tab-pane {
  width: 100%;
  float: left;
}
.compCaptacaoAbas .tab-content img {
  max-width: 100%;
}
.compCaptacaoAbas .Dropdown {
  display: none !important;
}

.containerTabContent.empresa {
  margin-top: 20px;
}
.containerTabContent.empresa section,
.containerTabContent.empresa article {
  padding: 0;
}
.containerTabContent.empresa .texto_empresa h1 {
  font-size: 20px;
  font-family: "CamptonBold";
  margin-bottom: 20px;
}
.containerTabContent.empresa .texto_empresa {
  font-family: "CamptonLight";
}
.containerTabContent.empresa .logo_empresa {
}
.containerTabContent.empresa .logo_empresa img {
  border: 1px solid #e5e5e5;
  max-width: 100%;
  padding: 20px;
  border-radius: 5px;
}
.containerTabContent .textoTab {
  font-family: "CamptonLight";
}
.containerTabContent.empreendimento .galeriaTabEmpreendimento {
  display: block;
  max-width: 100%;
  overflow: hidden;
}
.galeriaTabEmpreendimento .owl-nav {
  width: 100%;
  position: relative;
  margin: 10px auto 0px;
  position: relative;
  z-index: 1;
  padding: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  pointer-events: none;
}
.galeriaTabEmpreendimento .arrow {
  background-color: #fff;
  top: -80px;
  border: none;
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0 11px 22px 0 rgba(35, 35, 35, 0.08);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: initial;
}
.galeriaTabEmpreendimento .arrow.arrow-previous {
  left: 0;
}
.galeriaTabEmpreendimento .arrow.arrow-next {
  right: 0;
}
.containerTabContent.empreendimento .galeriaTabEmpreendimento .owl-item {
  display: inline-block;
}
.containerTabContent.empreendimento .carrousel_empreendimento .tab-slide {
  display: none !important;
}
.containerTabContent.empreendimento
  .carrousel_empreendimento
  .tab-slide.active {
  display: block !important;
}
.containerTabContent.empreendedor .galeriaTabEmpreendedor {
  display: block;
  max-width: 100%;
  overflow: hidden;
}
.galeriaTabEmpreendedor .owl-nav {
  width: 100%;
  position: relative;
  margin: 10px auto 0px;
  position: relative;
  z-index: 1;
  padding: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  pointer-events: none;
}
.galeriaTabEmpreendedor .arrow {
  background-color: #fff;
  top: -180px;
  border: none;
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0 11px 22px 0 rgba(35, 35, 35, 0.08);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: initial;
}
.galeriaTabEmpreendedor .arrow.arrow-previous {
  left: 0;
}
.galeriaTabEmpreendedor .arrow.arrow-next {
  right: 0;
}
.containerTabContent.empreendedor .galeriaTabEmpreendedor .owl-item {
  display: inline-block;
}
.containerTabContent.empreendedor .galeriaTabEmpreendedor .owl-item img {
  max-width: 100%;
}

.containerTabContent.empreendedor .image-gallery-image {
  max-width: 360px;
  min-width: 220px;
  margin: 0 auto;
}

.containerTabContent.investidores .box-investidores li:not(:last-child) {
  margin-right: 16px;
}
.containerTabContent.investidores .box-investidores li {
  margin: 0 0 16px;
}
.containerTabContent.viabilidade .cards {
  width: 100%;
  margin: 55px 0 0;
  padding: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
}
.containerTabContent.viabilidade .cards .card {
  width: 335px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0 0 35px;
  padding: 20px 0 25px;
  margin-right: 35px;
  text-align: center;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  -webkit-box-shadow: 10px 17px 40px rgba(121, 102, 255, 0.1);
  box-shadow: 10px 17px 40px rgba(121, 102, 255, 0.1);
}
.containerTabContent.viabilidade .description {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.containerTabContent.viabilidade .card-title {
  color: #29cc4a;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-family: "CamptonBold";
}
.containerTabContent.viabilidade .card-content {
  width: 100%;
  margin: 30px 0 0;
  padding: 0 40px;
}
.containerTabContent.viabilidade .card-content p {
  color: #3a3a3a;
  font-size: 16px;
  font-weight: 400;
  line-height: 0.1 0.2;
  margin: 0 0 25px;
  padding: 0;
}

.containerTabContent.juridico .card-row > div {
  display: flex;
  min-height: 190px;
}

.card-row .btn-success {
  border: none;
}

.card {
  width: 100%;
}

.containerTabContent.juridico .card-body {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.containerTabContent.vendas .left_vendas .title {
  color: #3a3a3a;
  font-size: 18px;
  width: 100%;
  display: block;
  font-weight: 700;
  margin: 0 0 10px;
  padding: 0;
}
.containerTabContent.vendas .left_vendas .unidades {
  color: #29cc4a;
  display: block;
  width: 100%;
  font-family: "CamptonBold";
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.containerTabContent.vendas .right_vendas .title {
  color: #3a3a3a;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}
.containerTabContent.vendas .right_vendas .title span {
  color: #29cc4a;
  font-size: 26px;
  margin-left: 6px;
}
.containerTabContent.vendas .right_vendas .list {
  width: 295px;
  margin: 40px 0 0;
  padding: 0;
}
.containerTabContent.vendas .right_vendas .list ul {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.containerTabContent.vendas .right_vendas .list ul li {
  position: relative;
  margin: 0 0 15px;
  padding: 0;
}
.containerTabContent.vendas .right_vendas .list ul li:not(:last-child) {
  margin-right: 30px;
}
.containerTabContent.vendas .right_vendas .list ul li:nth-child(5n + 0) {
  margin-right: 0;
}

.containerTabContent.vendas .right_vendas .legenda {
  position: absolute;
  right: 0;
  bottom: 15px;
  padding: 10px 12px 10px 10px;
  border: 1px solid #022169;
}
.containerTabContent.vendas .right_vendas .legenda .legenda-title {
  color: #3a3a3a;
  font-family: "Circular Std";
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.containerTabContent.vendas .right_vendas .legenda .option {
  color: #3a3a3a;
  font-family: "Circular Std";
  font-size: 13px;
  font-weight: 700;
  position: relative;
  margin: 5px 0 4px;
  padding: 0 0 0 18px;
}
.containerTabContent.vendas .right_vendas .legenda .option:last-child {
  margin: 0;
}
.containerTabContent.vendas .right_vendas .legenda .option::before {
  content: "";
  background-color: #29cc4a;
  position: absolute;
  top: 1px;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.containerTabContent.vendas .right_vendas .legenda .option:last-child::before {
  background-color: #3a3a3a;
}

.containerTabContent.avancoObra .row {
  width: 100%;
}
.containerTabContent.avancoObra .box_graficos {
  width: 100%;
  margin: 0;
  margin-top: 30px;
  padding: 0 0 25px;
}
.containerTabContent.avancoObra .progress {
  width: 100%;
  margin: 0;
  height: auto;
  overflow: inherit;
  padding: 10px;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
}

.containerTabContent.avancoObra .progress:not(:last-child) {
  margin-bottom: 15px;
}
.containerTabContent.avancoObra .progress .progresss-title {
  width: 100%;
  display: flex;
  font-size: 17px;
  font-family: "CamptonBold";
}
.containerTabContent.avancoObra .progress .flex {
  width: 100% !important;
  margin: 12px 0 0;
  padding: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.containerTabContent.avancoObra .progress .flex .bar {
  opacity: 1;
  transition-delay: 1.2s;
  will-change: opacity;
  transition: opacity 0.6s;
  background-color: #e7e7e7;
  width: 100%;
  height: 13px;
  margin: 0 0 10px;
  padding: 0;
  border-radius: 15px;
  overflow: hidden;
}
.containerTabContent.avancoObra .progress .flex .bar .line {
  background-color: #29cc4a;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 15px;
}
.containerTabContent.avancoObra .progress .flex .percentage {
  color: #3a3a3a;
  font-size: 16px;
  font-weight: 400;
  margin: -11px 0 0;
  padding: 0 0 0 15px;
  white-space: nowrap;
  width: 125px;
  flex-shrink: 0;
}

.containerTabContent.operacao .galeriaTabOperacao {
  display: block;
  max-width: 100%;
  overflow: hidden;
}
.galeriaTabOperacao .owl-nav {
  width: 100%;
  position: relative;
  margin: 10px auto 0px;
  position: relative;
  z-index: 1;
  padding: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  pointer-events: none;
}
.galeriaTabOperacao .arrow {
  background-color: #fff;
  top: -180px;
  border: none;
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0 11px 22px 0 rgba(35, 35, 35, 0.08);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: initial;
}
.galeriaTabOperacao .arrow.arrow-previous {
  left: 0;
}
.galeriaTabOperacao .arrow.arrow-next {
  right: 0;
}
.containerTabContent.operacao .galeriaTabOperacao .owl-item {
  display: inline-block;
}

/* FIX CARDS */

.cards {
  display: grid !important;
  grid-auto-rows: auto;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.cards .card {
  width: unset !important;
  display: flex !important;
  flex-flow: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: unset !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) !important;
}

.tab-link {
  cursor: pointer;
  white-space: nowrap;
}
.tab-link:hover {
  border-bottom: 3px solid black;
}
